/**
 *  style.css
 *  Ohbra
 *
 *  Last update by Bruno Bandeira on 09/01/2019.
 *  Contact: bruno@avamobile.com.br
 *
 *  Copyright © 2018 AVA Soft Solutions. All rights reserved.
 */


/* --------------------------------------------- */
/*  01 - LOGIN
/* --------------------------------------------- */

.container-bg {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    background: url('../img/bg-image.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-card {
    width: 450px;
    background-color: #fff;
}
.footer-login {
    width: 100%;
    position: absolute;
    background: transparent;
    padding: 15px;
    font-size: 17px;
    color: #fff;
    bottom: 0;
}
.footer-login ul li {
    padding-right: 15px;
}
.btn-b button {
    font-size: 15px;
    font-weight: normal;
}

.title-logo img {
    height: 55px;
}
.sub-logo {
    font-size: 16px;
}

/* .container-bg {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    position: absolute;
    background: url('../img/bg-image.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.bg-card {
    width: 450px;
    background-color: #fff;
}
.footer-login {
    width: 100%;
    position: absolute;
    background: transparent;
    padding: 15px;
    font-size: 17px;
    color: #fff;
    bottom: 0;
}
.footer-login ul li {
    padding-right: 15px;
}
.btn-b button {
    font-size: 15px;
    font-weight: normal;
}

.title-logo img {
    height: 55px;
}
.sub-logo {
    font-size: 16px;
} */

/* --------------------------------------------- */
/*  02 - SIDEBAR
/* --------------------------------------------- */

.logo a {
    max-width: 35%;
    border-radius: 0.1875rem;
}
.sidebar[data-active-color="info"] .nav li.active > a, 
.sidebar[data-active-color="info"] .nav li.active > a i, 
.sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"], 
.sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] i, 
.sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon, 
.sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a i, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"], 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] i, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a {
    color: #fff;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.off-canvas-sidebar .nav li>a, .sidebar .nav li>a {
    margin: 10px 15px 0;
    color: rgb(180, 179, 179);
    display: block;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 8px;
    line-height: 30px;
    opacity: .7;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
}

/* .logo a {
    max-width: 35%;
    border-radius: 0.1875rem;
}
.sidebar[data-active-color="info"] .nav li.active > a, 
.sidebar[data-active-color="info"] .nav li.active > a i, 
.sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"], 
.sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] i, 
.sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon, 
.sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a i, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"], 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] i, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active .sidebar-mini-icon, 
.off-canvas-sidebar[data-active-color="info"] .nav li.active > a[data-toggle="collapse"] ~ div > ul > li.active > a {
    color: #fff;
    opacity: 1;
}
.off-canvas-sidebar .nav li>a, .sidebar .nav li>a {
    margin: 10px 15px 0;
    color: rgb(180, 179, 179);
    display: block;
    text-decoration: none;
    position: relative;
    text-transform: uppercase;
    cursor: pointer;
    font-size: 12px;
    padding: 10px 8px;
    line-height: 30px;
    opacity: .7;
} */

/* --------------------------------------------- */
/*  03 - HEADER
/* --------------------------------------------- */
.navbar .breadcrumb .breadcrumb-item.breadcrumb-panel {
    color: #66615B;
}
.navbar.navbar-transparent a:not(.dropdown-item):not(.btn) {
    color: rgb(58, 57, 56);
}
.navbar .breadcrumb .breadcrumb-item:before {
    color: #66615B !important;
}
.back {
    font-size: 19px;
}

/* --------------------------------------------- */
/*  04 - CHOOSE STORE
/* --------------------------------------------- */
.navbar-store {
    box-shadow: 0 0px 0px 0 rgba(0, 0, 0, 0.16) !important;
}
.nav-a {
    margin: 45px 0 45px 0;
    padding: 15px 0 10px 0;
    background: #007eff;
    color: #fff;
}
.nav-a a {
    color: #fff;
}
.title-a {
    font-size: 18px;
}
.c-store p {
    color: #007eff; 
}
.c-store h4 {
    color: #007eff; 
}
.frontside:hover {
    background: #eff1f3;
    box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.16) !important;
}
.wrapper {
    widows: 100%;
    height:100vh;
    background: #e0e1e2;
}
.user-drop {
    font-size: 18px;
}
.logo-in-store img {
    height: 40px;
}
.container-header {
    height: 358px;
    margin-bottom: -70px;
    background: #007eff;
    color: #fff;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.16) !important;
}
.amc-t {
    font-size: 17px;
}
.amc-s {
    margin-top: 50px;
    position: relative;
    color: #fff;
}
.call {
    width: 40px;
    height: 40px;
    position: fixed;
    background: #007eff;
    color: #fff;
    padding: 9px 14px;
    font-size: 17px;
    line-height: 1.33;
    border-radius: 25px;
    right: 25px;
    bottom: 25px;
}
.call:hover {
    background: #007eff;
    color: #fff;
}

/* --------------------------------------------- */
/*  05 - MODAL
/* --------------------------------------------- */
.title-h-b {
    margin-left: 10px;
    margin-top: -27px;
    width: 170px;
    background-color: #fff !important;
    padding: 5px;
}
.data-est {
    margin-bottom: -25px;
}
.title-client-h-b {
    font-size: 18px;
}
.comp-currency {
    width: 64%;
}
.currency-total {
    float: left;
    font-size: 32px;
}
.alert-error {
    margin-top: 7px;
    font-weight: 700;
}
.img-receipt {
    width: 100%;
}

/* --------------------------------------------- */
/*  06 - FILTER
/* --------------------------------------------- */
.filter-header {
    margin-top: 25px;
    margin-bottom: 25px;
}
.area-filter {
    padding-top: 16px;
}

/* --------------------------------------------- */
/*  07 - PANEL
/* --------------------------------------------- */

.more-s {
    height: 450px;
    min-height: 450px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.more-s span{
    color: rgba(24, 23, 24, 0.781);
    font-size: 25px;
}

/* .more-s {
    height: 450px;
    min-height: 450px;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    -webkit-justify-content: center;
    justify-content: center;
}
.more-s span{
    color: rgba(24, 23, 24, 0.781);
    font-size: 25px;
} */